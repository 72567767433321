import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    perfil: null,
    // General
    registryField_28543: null, // nombre y apellidos.
    registryField_7147: null, // Fehca nacimiento.
    registryField_28544: null, // Telefono.
    registryField_7146: null, // email.
    registryField_7148: null, // nacionalidad.
    registryField_28516: null, // ciudad de residencia.
    registryField_7149: null, // nivel estudios.
    registryField_7150: null, // situacion laboral.
    registryField_7153: null, // Provincia
    registryField_28677: null, // Acepto condiciones
    // Formulario quiero emprender
    registryField_28521: null, // Has emprendido con anterioridad.
    registryField_28522: null, // que te motiva a emprender.
    registryField_28523: null, // Cuentas con una idea?.
    registryField_28524: null, // resumen idea.
    registryField_28525: null, // como surge idea.
    registryField_28526: null, // tipo necesidades.
    registryField_28527: null, // que te gustaria montar.
    registryField_28528: [], // que aspectos necesitas mayor apoyo.
    registryField_28529: null, // cuentanos sobre ti.
    // formulario ya tengo negocio
    registryField_28530: null, // que te motiva.
    registryField_28531: null, // que momento te encuentras.
    registryField_28532: null, // constituita juridicamente.
    registryField_28533: null, // sector.
    registryField_28548: null, // sector otro.
    registryField_28534: null, // resumento proyecto.
    registryField_28535: null, // validado viabilidad.
    registryField_28536: null, // afectado covid.
    registryField_28537: [], // 3 aspectos importantes.
    registryField_28549: null, // aspectos otro.
    registryField_28538: null, // como lo visualizas este año.
    registryField_28539: null, // ests en redes sociales?.
    registryField_28540: null, // cuentanos sobre ti.

    salidaQuieroEmprender: {
      registryField_29005: null, // email
      registryField_29006: 1, // tipo de form
      registryField_29007: null, // pregunta 1
      registryField_29008: null, // pregunta 2
      registryField_29009: null, // pregunta 3
      registryField_29010: null, // pregunta 4
      registryField_29011: null, // pregunta 5
      registryField_29012: null, // pregunta 6
      registryField_29013: null, // pregunta 7
      registryField_29014: null, // pregunta 8
      registryField_29015: null, // pregunta 9
      registryField_29016: null, // pregunta 10
    },
    salidaTengoUnNegocio: {
      registryField_29005: null, // email
      registryField_29006: 2, // tipo de form
      registryField_29017: null, // pregunta 1
      registryField_29018: null, // pregunta 2
      registryField_29019: null, // pregunta 3
      registryField_29020: null, // pregunta 4
      registryField_29021: null, // pregunta 5
      registryField_29022: null, // pregunta 6
      registryField_29023: null, // pregunta 7
      registryField_29024: null, // pregunta 8
      registryField_29025: null, // pregunta 9
      registryField_29066: null, // pregunta 10
    }


  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
