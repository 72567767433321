import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
  },
  {
    path: '/gracias',
    name: 'Gracias',
    component: () => import(/* webpackChunkName: "gracias" */ '../views/Gracias.vue')
  },
  {
    path: '/general',
    name: 'General',
    component: () => import(/* webpackChunkName: "general" */ '../views/General.vue')
  },
  {
    path: '/especifico',
    name: 'Especifico',
    component: () => import(/* webpackChunkName: "especifico" */ '../views/Especifico.vue')
  },
  {
    path: '/adios-quiero-emprender',
    name: 'AdiosQuieroEmprender',
    component: () => import(/* webpackChunkName: "salida" */ '../views/AdiosQuieroEmprender.vue')
  },
  {
    path: '/adios-tengo-negocio',
    name: 'AdiosTengoUnNegocio',
    component: () => import(/* webpackChunkName: "salida" */ '../views/AdiosTengoUnNegocio.vue')
  }
 


]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
